<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <!-- Card stats -->
    </base-header>

    <b-container fluid class="mt--7">
      <b-row>
        <b-col>
          <b-overlay :show="loading">
            <b-card no-body>
              <b-card-header class="border-1">
                <h3 class="mb-0">承認待ち一覧</h3>
              </b-card-header>
              <b-card-body>
                <div>
                  <b-form-group class="text-right">
                    <b-input-group size="sm">
                      <b-form-input type="search" v-model="search" placeholder="申請者名前で検索"></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!search" @click="search = null">クリア</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </div>
                <b-table
                  :items="tasks"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  stacked="md"
                  :filter="search"
                  :filter-included-fields="['employee_name']"
                  @filtered="onFiltered"
                  show-empty
                  small
                  hover
                >
                  <template #cell(updated_dt)="row">
                    {{ showUpdateDt(row.value) }}
                  </template>
                  <template #cell(status)="row">
                    <b-badge v-if="row.value==='10'" variant="info">申請中</b-badge>
                  </template>
                  <template #cell(actions)="row">
                    <b-button
                      size="sm" 
                      variant="link" 
                      :to="{name: 'task-approval-detail', params: {task_id: row.item.task, node_id: row.item.id}}"
                    >
                      <b-icon icon="pencil-square" variant="primary"></b-icon>
                    </b-button>
                  </template>
                </b-table>
              </b-card-body>
              <b-card-footer>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  size="md"
                ></b-pagination>
              </b-card-footer>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { ajax, common, config } from '../../utils';
import { helper } from '../common';

export default {
  data() {
    return {
      loading: true,
      tasks: [],
      fields: [
        { key: 'workflow_name', sortable: true, label: '名称' },
        { key: 'employee_name', sortable: true, label: '申請者' },
        { key: 'updated_dt', sortable: true, label: '申請日時' },
        { key: 'status', sortable: true, label: 'ステータス', class: 'text-center' },
        { key: 'actions', label: 'Actions', class: 'text-center' },
      ],
      currentPage: 1,
      perPage: config.rowsPerPage,
      search: null,
      totalRowsFilted: null,
    }
  },
  computed: {
    totalRows: function() {
      return this.search ? this.totalRowsFilted : this.tasks.length;
    },
  },
  created: function () {
    document.title = common.getPageName(this.$route.name);
    this.getApprovalTaskList();
  },
  methods: {
    getApprovalTaskList: function() {
      const vm = this;
      ajax.fetchGet(config.api.task.approval_list).then(data => {
        vm.tasks = data;
      }).catch(errors => {
        helper.show_errors(errors);
      }).finally(() => {
        vm.loading = false;
      });
    },
    showUpdateDt: function(d) {
      return common.formatDate(new Date(d), 'YYYY/MM/DD hh:mm');
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsFilted = filteredItems.length;
    },
  }
}
</script>